import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PurchaseUnit } from '@paypal/paypal-js';
import 'firebase/auth';
import { useCartItems } from 'hooks/useCartItems';
import { mutate } from 'swr';
import captureExceptionForClientSide from 'utils/captureExceptionForClientSide';
import captureMessageForClientSide from 'utils/captureMessageForClientSide';
import cookie from 'utils/cookie';
import logger from 'utils/logger';
import processGoogleTag from 'utils/processGoogleTag';
import processSalesOrderWithGoogleTag from 'utils/processSalesOrderWithGoogleTag';
import { scrollTo, scrollToElement } from 'utils/scrollTo';

import { ICauseMessage } from 'models/ICauseMessage';
import { IChampionClubPlan } from 'models/IChampionClubPlan';
import { ICheckoutCart } from 'models/ICheckoutCart';
import { Frequency } from 'models/IFrequency';
import { ISalesOrder } from 'models/ISalesOrder';
import { IShoppingCart } from 'models/IShoppingCart';
import {
  IShoppingCartItem,
  IShoppingCartItemRequest,
} from 'models/IShoppingCartItem';
import { ITicketBook } from 'models/ITicketBook';
import { fetcherNextJSAPI } from 'services/httpRequest';
import { PaypalService } from 'services/paypalService';
import { ShoppingCartClient } from 'services/ShoppingCartClient';

import { AuthContext } from './authContext';
import { useRecaptchaContext } from './recaptchaContext';

export enum MyCartSteps {
  myCart,
  account,
  payment,
}

interface CartContextProviderProps {
  actions: {
    addToCart: (shoppingCartItem: IShoppingCartItemRequest) => Promise<void>;
    checkoutCart: (params: ICheckoutCart) => Promise<void>;
    updateItem: (shoppingCartItem: IShoppingCartItem) => Promise<void>;
    deleteItem: (shoppingCartItem: IShoppingCartItem) => Promise<void>;
    addChampionClubPlanToCart: (
      championClubPlan: IChampionClubPlan,
      commenceNowInd: boolean,
    ) => Promise<void>;
    addTicketBookToCart: (
      ticketBook: ITicketBook,
      quantity: number,
      clubId?: number,
    ) => Promise<void>;
    mergeCart: () => Promise<void>;
    setShowCart: (show: boolean) => void;
    checkIfTheUserCanGoToThisStep: (step: MyCartSteps) => boolean;
    setRandomCauseMessage: (causeMessage: ICauseMessage) => any;
    setThankYouCauseMessage: (causeMessage: ICauseMessage) => any;
    toggleModal: () => any;
    createPaypalOrder: (
      purchaseUnits: PurchaseUnit,
      recaptchaToken: string,
    ) => Promise<string>;
    authorizePayment: (orderID: string, recaptchaToken: string) => Promise<any>;
    capturePaypalOrder: (
      orderId: string,
      recaptchaToken: string,
    ) => Promise<any | null>;
    onPaypalFailed: (message: string) => void;
    setPaidSalesOrder: (value: ISalesOrder | undefined) => void;
  };
  state: {
    isBuyingMonthly: boolean;
    thereIsChampionsClub: boolean;
    displayModal: boolean;
    paidSalesOrder?: ISalesOrder;
    userCart?: IShoppingCart;
    error?: string;
    showCart: boolean;
    randomCauseMessage?: ICauseMessage;
    thankYouCauseMessage?: ICauseMessage;
    checkoutErrorMessage?: string;
    isLoading: boolean;
    loadingStepContainer?: boolean;
    thankYouCauseMessageRef?: any;
  };
}

export const CartContext = createContext<CartContextProviderProps>({
  state: {
    paidSalesOrder: undefined,
    isBuyingMonthly: false,
    thereIsChampionsClub: false,
    userCart: undefined,
    error: undefined,
    showCart: false,
    displayModal: false,
    randomCauseMessage: undefined,
    thankYouCauseMessage: undefined,
    checkoutErrorMessage: undefined,
    isLoading: false,
    loadingStepContainer: false,
    thankYouCauseMessageRef: undefined,
  },
  actions: {
    toggleModal: () => {},
    addChampionClubPlanToCart: () => Promise.reject(),
    checkoutCart: () => Promise.reject(),
    addToCart: () => Promise.reject(),
    updateItem: () => Promise.reject(),
    deleteItem: () => Promise.reject(),
    addTicketBookToCart: () => Promise.reject(),
    mergeCart: () => Promise.reject(),
    setShowCart: () => {},
    checkIfTheUserCanGoToThisStep: () => false,
    setRandomCauseMessage: () => {},
    setThankYouCauseMessage: () => {},
    createPaypalOrder: async () => '',
    capturePaypalOrder: async () => null,
    onPaypalFailed: async () => null,
    setPaidSalesOrder: () => {},
    authorizePayment: () => Promise.reject(),
  },
});

const CartContextProvider: FC = ({ children }) => {
  const {
    actions: authActions,
    state: { user },
    isAuthenticated,
  } = useContext(AuthContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [randomCauseMessage, setRandomCauseMessage] = useState<
    ICauseMessage | undefined
  >();
  const [thankYouCauseMessage, setThankYouCauseMessage] = useState<
    ICauseMessage | undefined
  >();
  const { getRecaptchaToken } = useRecaptchaContext();
  const shoppingCartService = useMemo(() => new ShoppingCartClient(), []);

  const [loadingStepContainer, setLoadingStepContainer] = useState<boolean>(
    false,
  );

  const [paidSalesOrder, setPaidSalesOrder] = useState<ISalesOrder | undefined>(
    undefined,
  );

  const thankYouCauseMessageRef = useRef<HTMLDivElement>(null);

  const authenticateIfNotAuthenticated = useCallback(async () => {
    if (!user) {
      await authActions.loginAnonymous();
    }
  }, [authActions, user]);

  const toggleModal = useCallback(() => {
    setDisplayModal(!displayModal);
  }, [displayModal]);

  useEffect(() => {
    setPaidSalesOrder(undefined);
  }, []);

  const api = useMemo(() => fetcherNextJSAPI(), []);
  const {
    userCart,
    error: userCartError,
    mutate: mutateCartItems,
  } = useCartItems();

  const isBuyingMonthly =
    userCart?.shopping_cart_items?.some(
      (cartItem: IShoppingCartItem) =>
        cartItem?.frequency?.code === Frequency.Monthly,
    ) ?? false;

  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState<
    string | undefined
  >();

  const checkoutCart = useCallback(
    async (params: ICheckoutCart): Promise<void> => {
      try {
        setLoadingStepContainer(true);
        setCheckoutErrorMessage(undefined);
        const recaptchaToken = await getRecaptchaToken();
        if (!recaptchaToken) {
          logger.error({ params }, 'Recaptcha token is empty');
          throw new Error('Recaptcha token is empty');
        }
        const { data: salesOrder } = await shoppingCartService.checkoutMyCart({
          paymentType: params.paymentType,
          recaptchaToken,
          singleUseToken: params.singleUseToken,
          orderPayPal: params.orderPayPal,
        });
        setLoadingStepContainer(false);
        setPaidSalesOrder(salesOrder);
        await mutate(
          '/api/shopping_cart/me',
          { ...userCart, shopping_cart_items: [] },
          false,
        );
        if (thankYouCauseMessageRef.current) {
          scrollToElement(thankYouCauseMessageRef.current);
        }
        captureMessageForClientSide('Sales Order processed', {
          extra: {
            sales_order_id: salesOrder.id,
          },
        });
        processSalesOrderWithGoogleTag(salesOrder);
      } catch (error: any) {
        setLoadingStepContainer(false);
        const message =
          typeof (error.data?.message || error.data?.error) === 'string'
            ? error.data?.message || error.data?.error
            : 'An error occurred when tried to process your payment, please try again later.';

        captureExceptionForClientSide(new Error(message), {
          extra: {
            ...error.data,
            shopping_cart_id: userCart?.id,
          },
        });

        processGoogleTag({
          event: 'error',
          errorType: 'checkout error',
          errorMessage: message,
        });

        scrollTo({
          top: 0,
        });
        setCheckoutErrorMessage(message);
      }
    },
    [getRecaptchaToken, shoppingCartService, userCart],
  );
  const getIndexOfShoppingCartItemFromMyCart = useCallback(
    (product: IShoppingCartItemRequest | IShoppingCartItem) => {
      return (userCart?.shopping_cart_items || []).findIndex(
        existingShoppingCartItem =>
          (product.ticket_book_id &&
            existingShoppingCartItem.ticket_book_id &&
            product.ticket_book_id ===
              existingShoppingCartItem.ticket_book_id) ||
          (product.champion_club_plan_id &&
            existingShoppingCartItem.champion_club_plan_id &&
            product.champion_club_plan_id ===
              existingShoppingCartItem.champion_club_plan_id) ||
          (product.donation_campaign_id &&
            existingShoppingCartItem.donation_campaign_id &&
            product.donation_campaign_id ===
              existingShoppingCartItem.donation_campaign_id) ||
          (product.donation_option_id &&
            existingShoppingCartItem.donation_option_id &&
            product.donation_option_id ===
              existingShoppingCartItem.donation_option_id),
      );
    },
    [userCart],
  );

  const addProductTemporaryIntoTheCart = useCallback(
    async (product: IShoppingCartItemRequest) => {
      await mutateCartItems(
        {
          ...userCart,
          shopping_cart_items: [
            ...(userCart?.shopping_cart_items || []),
            {
              ...product,
              id: Math.floor(Math.random() * 1000),
            } as any,
          ],
        } as any,
        false,
      );
    },
    [mutateCartItems, userCart],
  );

  const updateProductTemporaryIntoTheCart = useCallback(
    async (product: IShoppingCartItemRequest, index: number) => {
      const matchingShoppingCartItem = (userCart?.shopping_cart_items || [])[
        index
      ];

      const updatedShoppingCartItems = (
        userCart?.shopping_cart_items || []
      ).concat();

      updatedShoppingCartItems[index] = {
        ...matchingShoppingCartItem,
        ...product,
        frequency: matchingShoppingCartItem.frequency,
      };

      await mutateCartItems(
        { ...userCart, shopping_cart_items: updatedShoppingCartItems } as any,
        false,
      );
    },
    [mutateCartItems, userCart],
  );

  const deleteProductTemporarilyFromTheCart = useCallback(
    async (product: IShoppingCartItem) => {
      const matchShoppingCartItemIndex = getIndexOfShoppingCartItemFromMyCart(
        product,
      );
      if (
        matchShoppingCartItemIndex !== undefined &&
        matchShoppingCartItemIndex >= 0
      ) {
        const updatedShoppingCartItems = (
          userCart?.shopping_cart_items || []
        ).concat();
        updatedShoppingCartItems.splice(matchShoppingCartItemIndex, 1);
        await mutateCartItems(
          { ...userCart, shopping_cart_items: updatedShoppingCartItems } as any,
          false,
        );
      }
    },
    [getIndexOfShoppingCartItemFromMyCart, mutateCartItems, userCart],
  );

  const deleteItem = useCallback(
    async (
      product: IShoppingCartItem,
      skipTemporaryUpdate = false,
      skipMutate = false,
    ) => {
      if (!skipTemporaryUpdate) {
        await deleteProductTemporarilyFromTheCart(product);
      }
      const { status } = await shoppingCartService.deleteItem(product);

      if (status === 204 && !skipMutate) {
        await mutateCartItems();
      }
      // TODO: status 202, item is already in the cart.
    },
    [deleteProductTemporarilyFromTheCart, mutateCartItems, shoppingCartService],
  );

  const addToCart = useCallback(
    async (
      product: IShoppingCartItemRequest,
      skipTemporaryUpdate = false,
      showModalContinueShopping = true,
    ) => {
      const [, recaptchaToken] = await Promise.all([
        authenticateIfNotAuthenticated(),
        getRecaptchaToken(),
      ]);
      if (!recaptchaToken) {
        console.error('Recaptcha token is not here');
        return;
      }
      const productIndex = getIndexOfShoppingCartItemFromMyCart(product);

      if (showModalContinueShopping) {
        setIsLoading(true);
        setDisplayModal(true);
      }

      let conditionalPromise = Promise.resolve();
      if (!skipTemporaryUpdate) {
        if (productIndex > -1) {
          const { quantity } = (userCart?.shopping_cart_items || [
            { quantity: 0 },
          ])[productIndex];
          conditionalPromise = updateProductTemporaryIntoTheCart(
            { ...product, quantity: product.quantity + quantity },
            productIndex,
          );
        } else {
          conditionalPromise = addProductTemporaryIntoTheCart(product);
        }
      }

      const [, { status }] = await Promise.all([
        conditionalPromise,
        shoppingCartService.addToCart(product, recaptchaToken),
      ]);
      if (status === 201) {
        await mutateCartItems();
        setIsLoading(false);
      }
      // TODO: status 202, item is already in the cart.
    },
    [
      authenticateIfNotAuthenticated,
      getRecaptchaToken,
      getIndexOfShoppingCartItemFromMyCart,
      shoppingCartService,
      userCart?.shopping_cart_items,
      updateProductTemporaryIntoTheCart,
      addProductTemporaryIntoTheCart,
      mutateCartItems,
    ],
  );

  const updateItem = useCallback(
    async (product: IShoppingCartItem) => {
      const [, recaptchaToken] = await Promise.all([
        authenticateIfNotAuthenticated(),
        getRecaptchaToken(),
      ]);
      if (!recaptchaToken) {
        throw new Error('Recaptcha token is empty');
      }

      const updatedShoppingCartItems = (
        userCart?.shopping_cart_items || []
      ).map((existingShoppingCartItem: IShoppingCartItem) =>
        existingShoppingCartItem.id === product.id
          ? {
              ...existingShoppingCartItem,
              ...product,
              ticket_book: existingShoppingCartItem.ticket_book
                ? {
                    ...(existingShoppingCartItem.ticket_book || {}),
                    ...(product.ticket_book || {}),
                  }
                : undefined,
              champion_club_plan: existingShoppingCartItem.champion_club_plan
                ? {
                    ...(existingShoppingCartItem.champion_club_plan || {}),
                    ...(product.champion_club_plan || {}),
                  }
                : undefined,
            }
          : existingShoppingCartItem,
      );

      await mutateCartItems(
        { ...userCart, shopping_cart_items: updatedShoppingCartItems } as any,
        false,
      );

      const { status } = await shoppingCartService.updateItem(
        product,
        recaptchaToken,
      );

      if (status === 200) {
        await mutateCartItems();
      }

      // TODO: status 202, item is already in the cart.
    },
    [
      authenticateIfNotAuthenticated,
      getRecaptchaToken,
      mutateCartItems,
      shoppingCartService,
      userCart,
    ],
  );

  const addTicketBookToCart = useCallback(
    async (ticketBook: ITicketBook, quantity = 1) => {
      const clubId = cookie.get('clubId');
      return addToCart({
        ticket_book: ticketBook,
        ticket_book_id: ticketBook.id,
        frequency: Frequency.OnceOff as string,
        quantity,
        club_id: clubId,
      });
    },
    [addToCart],
  );

  const addChampionClubPlanToCart = useCallback(
    async (championClubPlan: IChampionClubPlan, commenceNow = false) => {
      const shoppingCartItemFormat = {
        champion_club_plan: championClubPlan,
        champion_club_plan_id: championClubPlan.id,
        frequency: Frequency.Monthly as string,
        cc_commence_now_ind: commenceNow,
        quantity: 1,
      };
      /* *
       * Since you only can purchase one champions club plan, we need to check if the product inserted
       * is a champion club plan and if there is already any champion club in the cart. In case there is one already,
       * remove the existing one and add the new one.
       * */
      const championClubPlanItem = (userCart?.shopping_cart_items || []).find(
        ({ champion_club_plan_id }) => champion_club_plan_id,
      );
      if (championClubPlanItem) {
        const championClubPlanItemIndex = (
          userCart?.shopping_cart_items || []
        ).findIndex(({ champion_club_plan_id }) => champion_club_plan_id);

        // Delete the content from the cart temporarily and from database
        await Promise.all([
          deleteItem(championClubPlanItem, true),
          updateProductTemporaryIntoTheCart(
            shoppingCartItemFormat,
            championClubPlanItemIndex,
          ),
          addToCart(shoppingCartItemFormat, true),
        ]);
        return Promise.resolve();
      }
      return addToCart(shoppingCartItemFormat);
    },
    [
      addToCart,
      deleteItem,
      updateProductTemporaryIntoTheCart,
      userCart?.shopping_cart_items,
    ],
  );

  const mergeCart = useCallback(async (): Promise<void> => {
    if (!userCart || !userCart.shopping_cart_items) {
      await mutateCartItems();
      return;
    }

    const currentCartItems = userCart.shopping_cart_items.concat();
    const { data: previousCart } = await api.get<IShoppingCart | undefined>(
      '/shopping_cart/me',
    );
    const previousCartItems = previousCart?.shopping_cart_items || [];

    await Promise.all(
      previousCartItems.map((item: IShoppingCartItem) => {
        return deleteItem(item, true, true);
      }),
    );

    //  There is a problem on the RecaptchaAPI sdk where we can't
    // executeAsync method cannot be execute in parallel multiple times,
    // otherwise it halts and execute the last one only.
    //  So for this mergeCart where we need to insert many cartItems we need
    // to insert it synchronously.
    // https://github.com/dozoisch/react-google-recaptcha/issues/198
    const filteredCartItems = currentCartItems.filter(
      (item: IShoppingCartItem) => item.frequency?.code,
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const cartItem of filteredCartItems) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...resItem } = cartItem;
      // eslint-disable-next-line no-await-in-loop
      await addToCart(
        {
          ...resItem,
          frequency: cartItem.frequency?.code as string,
          item_type: undefined,
          ticket_book: undefined,
          donation_option: undefined,
          donation_campaign: undefined,
          club: undefined,
        },
        false,
        false,
      );
    }

    await mutateCartItems();
  }, [addToCart, api, deleteItem, mutateCartItems, userCart]);

  const checkIfTheUserCanGoToThisStep = useCallback(
    (stepChosen: MyCartSteps) => {
      switch (stepChosen) {
        case MyCartSteps.account:
          return (userCart?.shopping_cart_items?.length || 0) >= 1;
        case MyCartSteps.payment:
          return (
            (userCart?.shopping_cart_items?.length || 0) >= 1 && isAuthenticated
          );
        case MyCartSteps.myCart:
          return true;
        default:
          return false;
      }
    },
    [isAuthenticated, userCart?.shopping_cart_items?.length],
  );

  const thereIsChampionsClub = useMemo(() => {
    return (userCart?.shopping_cart_items || []).some(
      shoppingCartItem => shoppingCartItem.champion_club_plan_id,
    );
  }, [userCart]);

  const onPaypalFailed = useCallback((message: string) => {
    setCheckoutErrorMessage(message);
  }, []);

  const createPaypalOrder = useCallback(
    async (purchaseUnits: PurchaseUnit, recaptchaToken: string) => {
      if (!userCart) {
        throw new Error(
          'Please, try refreshing and starting over. If the error persist, please contact support.',
        );
      }
      const paypalService = new PaypalService(fetcherNextJSAPI());
      const orderID: string = await paypalService
        .create(purchaseUnits, recaptchaToken, userCart.id)
        .then(order => order.data.id)
        .catch(err => {
          logger.error(
            { orderID, paypalError: err },
            'Paypal failed to create order',
          );
          onPaypalFailed('Paypal failed to create order');
        });
      return orderID;
    },
    [onPaypalFailed, userCart],
  );

  const capturePaypalOrder = useCallback(
    async (orderId: string, recaptchaToken: string) => {
      if (!userCart) {
        throw new Error(
          'Please, try refreshing and starting over. If the error persist, please contact support.',
        );
      }
      const paypalService = new PaypalService(fetcherNextJSAPI());
      const payment = await paypalService
        .capture(orderId, recaptchaToken, userCart.id)
        .then(capturePayment => capturePayment.data)
        .catch(err => {
          logger.error(
            { orderId, paypalError: err },
            'Paypal failed to capture order',
          );
          onPaypalFailed('Paypal failed to capture order');
        });

      return payment;
    },
    [onPaypalFailed, userCart],
  );

  const authorizePayment = useCallback(
    async (orderID: string, recaptchaToken: string) => {
      if (!userCart) {
        throw new Error(
          'Please, try refreshing and starting over. If the error persist, please contact support.',
        );
      }
      const paypalService = new PaypalService(fetcherNextJSAPI());
      const payment = await paypalService
        .authorize(orderID, recaptchaToken, userCart.id)
        .then(capturePayment => capturePayment.data)
        .catch(err => {
          logger.error(
            {
              orderID,
              errorMessage: err.message,
              tag: 'paypal-authorize-payment',
            },
            'Paypal failed to authorize order',
          );
          onPaypalFailed('Paypal failed to authorize order');
        });

      return payment;
    },
    [onPaypalFailed, userCart],
  );

  const actions = {
    checkoutCart,
    addToCart,
    addChampionClubPlanToCart,
    addTicketBookToCart,
    mergeCart,
    setShowCart,
    checkIfTheUserCanGoToThisStep,
    updateItem,
    deleteItem,
    setRandomCauseMessage,
    setThankYouCauseMessage,
    toggleModal,
    createPaypalOrder,
    capturePaypalOrder,
    onPaypalFailed,
    setPaidSalesOrder,
    authorizePayment,
  };

  const state = {
    paidSalesOrder,
    isBuyingMonthly,
    thereIsChampionsClub,
    userCart,
    error: userCartError,
    showCart,
    randomCauseMessage,
    thankYouCauseMessage,
    checkoutErrorMessage,
    displayModal,
    isLoading,
    loadingStepContainer,
    thankYouCauseMessageRef,
  };

  return (
    <CartContext.Provider value={{ state, actions }}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = (): CartContextProviderProps => {
  return useContext(CartContext);
};

export { useCart };

export default CartContextProvider;
