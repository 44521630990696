import * as Sentry from '@sentry/nextjs';

import { EnvironmentTypes } from './src/constants/environmentTypes';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE,
    dsn:
      SENTRY_DSN ||
      'https://f54e0775674542558fb7ba9f330dee68@o124009.ingest.sentry.io/5862068',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate:
      process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === EnvironmentTypes.production
        ? 0.2
        : 1,
    tracesSampler: samplingContext => {
      // Ignore health check logs
      if (
        samplingContext?.transactionContext?.name === 'GET /api/health_check' ||
        samplingContext?.transactionContext?.name === '/'
      ) {
        return false;
      }
      return true;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
