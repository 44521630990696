import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { BaseResponse, fetcherNextJSAPI } from 'services/httpRequest';

export const RecaptchaContext = createContext<{
  getRecaptchaToken: () => Promise<string | null>;
  loading?: boolean;
  executeVerify: (
    recaptchaToken: string,
  ) => Promise<BaseResponse<{ data: []; error: string }>>;
}>({
  getRecaptchaToken: () => Promise.reject(),
  executeVerify: () => Promise.reject(),
});

const RecaptchaContextProvider: FC = ({ children }) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const loading = useMemo(() => !!recaptchaRef.current, [recaptchaRef]);

  const getRecaptchaToken = useCallback(async () => {
    if (!recaptchaRef.current) {
      return Promise.resolve(null);
    }
    return recaptchaRef.current.executeAsync();
  }, [recaptchaRef]);

  const executeVerify = useCallback(async (recaptchaToken: string) => {
    const api = fetcherNextJSAPI();
    return api.post<{ data: []; error: string }>('/recaptcha/verify', {
      token: recaptchaToken,
    });
  }, []);

  return (
    <RecaptchaContext.Provider
      value={{
        executeVerify,
        getRecaptchaToken,
        loading,
      }}
    >
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY}
      />
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaContextProvider;

export const useRecaptchaContext = () => useContext(RecaptchaContext);
