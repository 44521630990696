import { OrderResponseBody } from '@paypal/paypal-js';
import { formatRecaptchaHeader } from 'utils/formatRecaptchaHeader';

import { IChampionClubPlan } from 'models/IChampionClubPlan';
import { Frequency } from 'models/IFrequency';
import { ISalesOrder } from 'models/ISalesOrder';
import {
  IShoppingCartItem,
  IShoppingCartItemRequest,
} from 'models/IShoppingCartItem';
import { ITicketBook } from 'models/ITicketBook';
import { PaymentType } from 'models/PaymentType';

import HttpRequest, { fetcherNextJSAPI, BaseResponse } from './httpRequest';

export class ShoppingCartClient {
  public httpRequester: HttpRequest;

  constructor() {
    this.httpRequester = fetcherNextJSAPI();
  }

  async addToCart(
    shoppingCartItem: IShoppingCartItemRequest,
    recaptchaToken: string,
  ): Promise<BaseResponse<IShoppingCartItem>> {
    return this.httpRequester.post<IShoppingCartItem>(
      '/shopping_cart_item',
      {
        ...shoppingCartItem,
        ticket_book: undefined,
        champion_club_plan: undefined,
        donation_campaign: undefined,
      },
      {
        headers: formatRecaptchaHeader(recaptchaToken),
      },
    );
  }

  async updateItem(
    salesOrderItem: IShoppingCartItem,
    recaptchaToken: string,
  ): Promise<BaseResponse<IShoppingCartItem>> {
    return this.httpRequester.put<IShoppingCartItem>(
      `/shopping_cart_item/${salesOrderItem.id}`,
      salesOrderItem,
      {
        headers: formatRecaptchaHeader(recaptchaToken),
      },
    );
  }

  async deleteItem(
    salesOrderItem: IShoppingCartItem,
  ): Promise<BaseResponse<IShoppingCartItem>> {
    return this.httpRequester.delete(
      `/shopping_cart_item/${salesOrderItem.id}`,
    );
  }

  async addTicketBookToCart(
    ticketBook: ITicketBook,
    quantity = 1,
    recaptchaToken: string,
  ): Promise<BaseResponse<IShoppingCartItem>> {
    return this.addToCart(
      {
        ticket_book_id: ticketBook.id,
        frequency: Frequency.OnceOff,
        quantity,
      },
      recaptchaToken,
    );
  }

  async addChampionClubPlanToCart(
    championClubPlan: IChampionClubPlan,
    commenceNow = false,
    recaptchaToken: string,
  ): Promise<BaseResponse<IShoppingCartItem>> {
    return this.addToCart(
      {
        champion_club_plan_id: championClubPlan.id,
        frequency: Frequency.Monthly,
        cc_commence_now_ind: commenceNow,
        quantity: 1,
      },
      recaptchaToken,
    );
  }

  async checkoutMyCart({
    paymentType,
    singleUseToken,
    orderPayPal,
    recaptchaToken,
  }: {
    paymentType: PaymentType;
    singleUseToken?: string;
    orderPayPal?: OrderResponseBody;
    recaptchaToken: string;
  }): Promise<BaseResponse<ISalesOrder>> {
    return this.httpRequester.post<ISalesOrder>(
      '/sales_order',
      {
        singleUseToken,
        paymentType,
        orderPayPal,
      },
      {
        headers: formatRecaptchaHeader(recaptchaToken),
      },
    );
  }
}
