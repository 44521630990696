export enum PaymentType {
  PAYPAL = 'PayPal',
  WESTPAC = 'WestPac',
  GOOGLE_PAY = 'GooglePay',
  APPLE_PAY = 'ApplePay',
}

export enum PaymentIsEnabled {
  YES = 'yes',
  NO = 'no',
}

export enum PaymentStatus {
  approved = 'Approved',
  fail = 'Fail',
  token = 'Token',
}
