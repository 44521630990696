import firebase from 'firebase/app';
import 'firebase/auth';

const getUserForSentryFromClientSide = () => {
  const firebaseUser = firebase.auth().currentUser;
  if (!firebaseUser) {
    return undefined;
  }
  return {
    id: firebaseUser.uid,
    email: firebaseUser.email || undefined,
    username: firebaseUser.email || undefined,
  };
};
export default getUserForSentryFromClientSide;
