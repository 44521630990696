import { IError } from 'models/HttpError';
import { IUser } from 'models/IUser';

import { Action } from './appState';

export interface AuthState {
  user?: IUser;
  error?: IError;
  loading?: boolean;
}

export enum AuthTypes {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'CREATE_USER_FAILURE',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',
  SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS',
  AUTH_FAILURE = 'AUTH_FAILURE',
  AUTH_INITIALISED = 'AUTH_INITIALISED',
  AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR',
}

const initialState: AuthState = {
  user: undefined,
  error: undefined,
  loading: false,
};

const reducer = (state: AuthState, action: Action<AuthTypes>): AuthState => {
  switch (action.type) {
    case AuthTypes.AUTH_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case AuthTypes.LOGIN:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: undefined,
        loading: false,
      };
    case AuthTypes.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AuthTypes.CREATE_USER:
      return {
        ...state,
        loading: true,
      };
    case AuthTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case AuthTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AuthTypes.UPDATE_USER:
      return {
        ...state,
        loading: true,
      };
    case AuthTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case AuthTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AuthTypes.SIGNOUT_SUCCESS:
      return {
        ...state,
        user: undefined,
        error: undefined,
        loading: false,
      };
    case AuthTypes.AUTH_INITIALISED:
      return {
        ...state,
        error: undefined,
      };
    case AuthTypes.AUTH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      throw new Error('Action not implemented');
  }
};

export { initialState };

export default reducer;
