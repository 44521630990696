import { captureException } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

import { HttpRequestError } from 'services/httpRequestError';

import getUserForSentryFromClientSide from './getUserForSentryFromClientSide';
import logger from './logger';

const captureExceptionForClientSide = (
  exception: any,
  captureContext: CaptureContext | undefined = {},
) => {
  const user = getUserForSentryFromClientSide();
  let error: any = exception;
  // Checking if it is firebaseError
  if (error?.code && error?.message) {
    error = new Error(`[${error.code}]: ${error.message}`);
  }
  if (exception instanceof HttpRequestError) {
    error = new Error(
      exception.data?.error ||
        exception.data?.message ||
        `An HttpRequestError ocurred. Received ${exception.status}`,
    );
  }

  logger.error(
    { userId: user?.id, captureContext },
    `[Client-Side]: ${String(error)}`,
  );

  return captureException(
    error instanceof Error
      ? new Error(`[Client-Side]: ${error.toString()}`)
      : new Error(`[Client-Side]: ${JSON.stringify(error)}`),
    {
      user,
      ...captureContext,
    },
  );
};
export default captureExceptionForClientSide;
