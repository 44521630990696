import { useMemo } from 'react';

import useSWR from 'swr';

import { IShoppingCart } from 'models/IShoppingCart';
import { fetcherNextJSAPI } from 'services/httpRequest';

export const useCartItems = () => {
  const api = useMemo(() => fetcherNextJSAPI(), []);

  const { data: userCart, error, mutate, isValidating } = useSWR<IShoppingCart>(
    '/api/shopping_cart/me',
    () => api.get<IShoppingCart>('/shopping_cart/me').then(({ data }) => data),
  );

  return {
    userCart,
    error,
    isValidating,
    mutate,
  };
};
