import { captureMessage } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

import getUserForSentryFromClientSide from './getUserForSentryFromClientSide';
import logger from './logger';

const captureMessageForClientSide = (
  message: string,
  captureContext: CaptureContext | undefined = {},
) => {
  const user = getUserForSentryFromClientSide();
  logger.info({ userId: user?.id, captureContext }, message);
  return captureMessage(`[Client-Side]: ${message}`, {
    user,
    ...captureContext,
  });
};
export default captureMessageForClientSide;
