import { FC, createContext, useContext, useState } from 'react';

import { IPersona } from 'models/IPersona';

export interface IAppContextProps {
  host: string;
  minPaymentAmount?: number;
  applepayEnabled: boolean;
  googlepayEnabled: boolean;
  paypalEnabled: boolean;
  actions: {
    setPersona(persona: IPersona): any;
  };
  state: {
    persona?: IPersona;
  };
}
export const AppContext = createContext<IAppContextProps>({
  host: '',
  actions: {
    setPersona: () => {},
  },
  state: {
    persona: undefined,
  },
  minPaymentAmount: undefined,
  applepayEnabled: false,
  googlepayEnabled: false,
  paypalEnabled: false,
});

export interface IAppContextProviderProps {
  host: string;
  minPaymentAmount: number;
  applepayEnabled: boolean;
  googlepayEnabled: boolean;
  paypalEnabled: boolean;
}
const AppContextProvider: FC<IAppContextProviderProps> = ({
  children,
  ...props
}) => {
  const [persona, setPersona] = useState<IPersona | undefined>();
  const actions = {
    setPersona,
  };
  return (
    <AppContext.Provider
      value={{
        ...props,
        actions,
        state: {
          persona,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): IAppContextProps => {
  return useContext(AppContext);
};

export default AppContextProvider;
