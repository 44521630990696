import { ISalesOrder } from 'models/ISalesOrder';
import { IShoppingCartItem } from 'models/IShoppingCartItem';

import currencyFormat from './curencyFormat';
import processGoogleTag from './processGoogleTag';

enum ShoppingCartItemTypes {
  TicketBook = 'Ticket Book',
  Donation = 'Donation',
  Subscription = 'Subscription',
}

const getAmountToPayFromShoppingCartItem = (
  shoppingCartItem: IShoppingCartItem,
) => {
  return (
    (shoppingCartItem.champion_club_plan?.dollar_value ||
      shoppingCartItem.ticket_book?.ticket_book_dollar_amount ||
      shoppingCartItem.donation_option?.amount ||
      shoppingCartItem.custom_unit_amount ||
      0) * shoppingCartItem.quantity
  );
};

const getCategoryByShoppingCartItem = (
  shoppingCartItem: IShoppingCartItem,
): ShoppingCartItemTypes | undefined => {
  if (shoppingCartItem.ticket_book_id) {
    return ShoppingCartItemTypes.TicketBook;
  }
  if (
    shoppingCartItem.donation_option_id ||
    shoppingCartItem.donation_campaign
  ) {
    return ShoppingCartItemTypes.Donation;
  }
  if (shoppingCartItem.champion_club_plan_id) {
    return ShoppingCartItemTypes.Subscription;
  }
  console.error('[getCategoryByShoppingCarItem]', shoppingCartItem);
  return undefined;
};

const getProductCode = (
  shoppingCartItem: IShoppingCartItem,
  category?: ShoppingCartItemTypes,
) => {
  if (category === ShoppingCartItemTypes.Donation) {
    return shoppingCartItem.donation_option?.donation_campaign?.product_code;
  }
  if (category === ShoppingCartItemTypes.Subscription) {
    return shoppingCartItem.champion_club_plan?.product_code;
  }
  return shoppingCartItem.ticket_book?.ticket_book_type?.product_code;
};

const processSalesOrderWithGoogleTag = (salesOrder: ISalesOrder) => {
  processGoogleTag({ event: 'Purchase', ecommerce: null });
  processGoogleTag({
    event: 'Purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: salesOrder.id,
          affiliation: 'Surf Life Saving Lottery - Online Store',
          revenue: (salesOrder.shopping_cart_items || []).reduce(
            (acc, shoppingCartItem) => {
              return acc + getAmountToPayFromShoppingCartItem(shoppingCartItem);
            },
            0,
          ),
          quantity: (salesOrder.shopping_cart_items || []).reduce(
            (acc, shoppingCartItem) => {
              return acc + shoppingCartItem.quantity;
            },
            0,
          ),
          userEmail: salesOrder.customer?.email,
        },
        products: (salesOrder.shopping_cart_items || []).map(
          shoppingCartItem => {
            const category = getCategoryByShoppingCartItem(shoppingCartItem);
            const price = getAmountToPayFromShoppingCartItem(shoppingCartItem);
            const ticketBook = shoppingCartItem.ticket_book;
            return {
              draw_no: ticketBook?.draw?.draw_no,
              subscription_plan_id: shoppingCartItem.champion_club_plan_id,
              id: getProductCode(shoppingCartItem, category),
              name: [
                ShoppingCartItemTypes.Donation,
                ShoppingCartItemTypes.Subscription,
              ].includes(category as ShoppingCartItemTypes)
                ? `${currencyFormat(price)} ${category}`
                : `${ticketBook?.ticket_qty || ''} Lottery ${
                    ticketBook?.draw?.draw_no || ''
                  }`,
              price,
              commence_now: shoppingCartItem.cc_commence_now_ind,
              category,
              campaign_id: shoppingCartItem.donation_campaign_id,
              frequency: shoppingCartItem.frequency?.name,
              quantity: shoppingCartItem.quantity,
            };
          },
        ),
      },
    },
  });
};
export default processSalesOrderWithGoogleTag;
