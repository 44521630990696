export const scrollTo = (options: any) => {
  if (!window) {
    return;
  }
  const HEADER_HEIGHT =
    document.querySelector('.header-menu')?.clientHeight || 146;
  const newOptions = options;
  if (newOptions.top) {
    newOptions.top -= HEADER_HEIGHT;
  }
  window.scrollTo({
    behavior: 'smooth',
    ...options,
  });
};

export const scrollToElement = (
  element: Element,
  position: 'top' | 'bottom' = 'top',
  options: any = {},
) => {
  if (!process.browser) {
    return;
  }
  if (position === 'bottom') {
    element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    return;
  }
  const value =
    element.getBoundingClientRect()[position] +
    (window.pageYOffset || document.documentElement.scrollTop);
  scrollTo({
    top: value,
    behavior: 'smooth',
    ...options,
  });
};
