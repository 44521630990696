/* eslint-disable */
export const GTMPageView = (url: string) => {
  interface PageEventProps {
    event: string;
    page: string;
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };

  if (process.browser && window.dataLayer) {
    window.dataLayer.push(pageEvent);
  }
  return pageEvent;
};
