import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import '../styles/index.scss';

import cssVars from 'css-vars-ponyfill';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress'; // nprogress module
import objectFitImages from 'object-fit-images';
import { GTMPageView } from 'utils/GTMPageView';
import isIE from 'utils/isIE';

import AppContextProvider from 'contexts/appContext';
import AuthContextProvider from 'contexts/authContext';
import CartContextProvider from 'contexts/cartContext';
import RecaptchaContextProvider from 'contexts/recaptchaContext';
import { IStandardSettingProps } from 'models/IStandardSetting';
import { PaymentIsEnabled } from 'models/Payment';

interface IProps extends AppProps {
  defaultSettings: IStandardSettingProps;
}

if (process.browser) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_API_KEY,
    authDomain:
      process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_AUTH_DOMAIN,
    databaseURL:
      process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_DATABASE_URL,
    storageBucket:
      process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_STORAGE_BUCKET,
    messagingSenderId: parseInt(
      process.env
        .NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_MESSAGING_SENDER_ID || '0',
      10,
    ),
    projectId:
      process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_PROJECT_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_APP_ID,
    measurementId:
      process.env.NEXT_PUBLIC_FIREBASE_SERVICE_WEB_APP_JS_SDK_MEASUREMENT_ID,
  });

  if (!Node.prototype.contains) {
    Node.prototype.contains = function (el) {
      // eslint-disable-next-line
      while ((el = el.parentNode)) {
        if (el === this) return true;
      }
      return false;
    };
  }
}

// This default export is required in a new `pages/_app.js` file.
function MyApp({ Component, pageProps }: IProps) {
  const router = useRouter();
  cssVars({
    // Options...
  });

  const host: string = useMemo(() => {
    if (process.browser) {
      return window.location?.origin;
    }
    return '';
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeError', () => NProgress.done());
    router.events.on('routeChangeComplete', (url: string) => {
      NProgress.done();
      GTMPageView(url);
    });
    GTMPageView(window.location.href);

    return () => {
      router.events.off('routeChangeStart', () => NProgress.done());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIE()) {
      setTimeout(() => {
        objectFitImages('img.object-fit-cover', { watchMQ: true });
      });
    }
  }, [router]);

  return (
    <RecaptchaContextProvider>
      <AuthContextProvider>
        <CartContextProvider>
          <AppContextProvider
            host={host}
            minPaymentAmount={Number(
              pageProps.commonPropsForMainLayout?.settings?.minPaymentAmount
                ?.text_value,
            )}
            applepayEnabled={
              pageProps.commonPropsForMainLayout?.settings?.applepayEnabled
                ?.text_value === PaymentIsEnabled.YES
            }
            googlepayEnabled={
              pageProps.commonPropsForMainLayout?.settings?.googlepayEnabled
                ?.text_value === PaymentIsEnabled.YES
            }
            paypalEnabled={
              pageProps.commonPropsForMainLayout?.settings?.paypalEnabled
                ?.text_value === PaymentIsEnabled.YES
            }
          >
            <Head>
              <title>Surf Life Saving Lottery</title>
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5"
              />
            </Head>
            <Component host={host} {...pageProps} />
          </AppContextProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </RecaptchaContextProvider>
  );
}

export default MyApp;
