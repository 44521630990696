import HttpRequest, { BaseResponse } from './httpRequest';

export class PaypalService {
  constructor(private httpRequestor: HttpRequest) {}

  public async create(
    purchaseUnits: any,
    recaptchaToken: string,
    userCartId: number,
  ): Promise<BaseResponse<any>> {
    return this.httpRequestor.post(
      '/paypal/create',
      { purchaseUnits, userCartId },
      {
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      },
    );
  }

  public async capture(
    orderId: string,
    recaptchaToken: string,
    userCartId: number,
  ): Promise<BaseResponse<any>> {
    return this.httpRequestor.post(
      '/paypal/capture',
      { orderId, userCartId },
      {
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      },
    );
  }

  public async authorize(
    orderId: string,
    recaptchaToken: string,
    userCartId: number,
  ): Promise<BaseResponse<any>> {
    return this.httpRequestor.post(
      '/paypal/authorize',
      { orderId, userCartId },
      {
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      },
    );
  }
}
